<template>
    <v-hover>
        <template v-slot:default="{ hover }">

            <v-card tile :elevation="hover ? 15: 2">
                
                <v-card-title class="font-weight-normal"
                    :class="[getLifeEventTextFullFormat(item.type), {'right-align' : rightAlignText}]"
                    v-html="item.title">

                </v-card-title>
                <v-card-text>
                    <div v-if="$vuetify.breakpoint.mobile" class="mr-1">
                        <h3>{{item.year}}</h3>
                    </div>
                    <div class="pt-4" :class="{'text-right': rightAlignText}" v-html="item.desc">
                    </div>
                    <div>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
    export default {

        props: {
            item: {
                required: true,

            },

            index: {
                required: true,
            },


        },
        computed: {
            rightAlignText: function () {
                return (this.index % 2 == 1 && !this.$vuetify.breakpoint.mobile)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .right-align {
        justify-content: flex-end;
    }
</style>