<template>
  <v-app>

    <v-main>
      <app-the-legend></app-the-legend>
      <v-container>
        <v-row>
          <v-col>
            <app-time-line></app-time-line>
          </v-col>
        </v-row>
      </v-container>

    </v-main>
  </v-app>
</template>

<script>
  import CvTimeLine from '@/components/ui/CvTimeLine.vue'
  import TheLegend from '@/components/ui/TheLegend.vue'


  export default {
    name: 'App',

    components: {
      'app-time-line': CvTimeLine,
      'app-the-legend': TheLegend,
    },

    data: () => ({
      //
    }),
  };
</script>