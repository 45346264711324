<template>
    <v-timeline-item :color="getLifeEventColor(item.type)" small>
        <template v-slot:opposite>
            <div class="mt-1">
                <span class="font-weight-light headline" :class="getLifeEventTextFullFormat(item.type)"
                    v-text="item.year"></span>
            </div>

        </template>
        <app-time-line-item-description :item="item" :index="index">
            {{item.desc}}

        </app-time-line-item-description>

    </v-timeline-item>

</template>


<script>
    import AppTimeLineItemDescription from '@/components/ui/CvTimeLineItemDescription.vue';

    export default {
        components: {
            'app-time-line-item-description': AppTimeLineItemDescription,
        },
        props: {
            item: {
                required: true,

            },

            index: {
                required: true,
            },



        },


    }
</script>
<style lang="scss">
    .alternate {
        background-color: #f8f8f8 !important;
    }

    .v-timeline-item__opposite {
        align-self: flex-start !important;
    }

    .v-timeline-item__dot--small {
        margin-top: 8px !important;
    }
</style>