<template>
    <v-app-bar fixed app dense>
        <template v-for="(color, key, i) in getAllColors()">
            <div :key="i" :class="getLifeEventTextFullFormat(key)" class="v-timeline-item__dot v-timeline-item__dot--small mt-0 mr-2">

                <div :class="`v-timeline-item__inner-dot ${getLifeEventColor(key)}`"></div>
            </div>
            <div :key="key+i" :class="getLifeEventTextFullFormat(key)" class="responsive-text">
                <span>{{color.text}}</span>
            </div>
            
            <v-spacer :key="key" v-if="i+1 != Object.keys(getAllColors()).length"></v-spacer>
        </template>


    </v-app-bar>
</template>

<script>
    export default {
        created() {
           
        }
    }
</script>


<style lang="scss" scoped>
    @media screen and (max-width: 600px) {
        .responsive-text {
            font-size: 0.85rem !important;
        }
    }
</style>