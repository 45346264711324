<template>
    <v-timeline class="ma-4" align-top :dense="$vuetify.breakpoint.mobile">
        <app-time-line-item v-for="(item,index) in items" :key="index" :item="item" :index="index"></app-time-line-item>
    </v-timeline>
</template>


<script>
    import CvTimeLineItem from '@/components/ui/CvTimeLineItem.vue'
    export default {


        name: 'CvTimeLine',
        components: {
            'app-time-line-item': CvTimeLineItem,
        },
        data() {

            return {
                items: [ 
                    {
                        year: '2021',
                        title: 'Selvstændig med Bestilmere',
                        desc: 'Beslutter sammen med min storebror at prøve at starte noget op selv. Vi tilbyder konsulent assistance inden for bl.a. BizTalk, Azure samt andre områder.',
                        type: 'job',

                    },
                    {
                        year: '2021',
                        title: 'Endnu en af livets største gaver',
                        desc: 'Er så heldig at blive beriget med endnu et helt perfekt barn i form af vores dejlige datter, Alba. Er lykkelig for at være hjemme med hele familien hver dag, og er ikke i tvivl om at det er det rigtige karrierevalg, der blev foretaget sidste år.',
                        type: 'life',

                    },
                    {
                        year: '2021',
                        title: 'System Consultant og tilbage til IT verdenen',
                        desc: '<p>Bytelab, Aarhus</p><p>Vender tilbage til tidligere og nye kollegaer i Bytelab, hvor vi digitaliserer bilbranchen med innovative IT-systemer til leasing og finansiering. Arbejder som System Consultant, hvor ca. halvdelen af tiden går med udvikling i C# og VB.net, samt anden halvdel som tekninsk assistance i Customer Care afdelingen. </p>',
                        type: 'job',

                    },
                    {
                        year: '2020',
                        title: 'Stadig kaptajn i lavprisbranchen.',
                        desc: '<p>Ryanair, Malta</p><p>Samme job som tidligere, nu bare i et varmere land.</p>Tager sammen med familien til Malta for at få et udenlandseventyr, dog kommer der en lille sygdom i vejen, der forstyr manges hverdag. Er dog utrolig heldig at være på, hvad der føles som en meget lang ferie, da der ikke er meget flyvning. Nyder vandet, vejret og, absolut ikke mindst, at være sammen med familien hver dag.',
                        type: 'job',

                    },
                    {
                        year: '2020',
                        title: 'Stadig kaptajn i lavprisbranchen.',
                        desc: '<p>Ryanair, Malta</p><p>Samme job som tidligere, nu bare i et varmere land.</p>Tager sammen med familien til Malta for at få et udenlandseventyr, dog kommer der en lille sygdom i vejen, der forstyr manges hverdag. Er dog utrolig heldig at være på, hvad der føles som en meget lang ferie, da der ikke er meget flyvning. Nyder vandet, vejret og, absolut ikke mindst, at være sammen med familien hver dag.',
                        type: 'job',

                    },
                    {
                        year: '2016-2019',
                        title: 'Kaptajn i lavprisbranchen',
                        desc: '<p>Ryanair, Hamburg, Tyskland</p>Vender tilbage til luften som lavpriskaptajn. Arbejder i et utroligt struktureret og internationalt arbejdsmiljø.',
                        type: 'job',

                    },
                    {
                        year: '2016',
                        title: 'Livets største gave',
                        desc: 'Bliver far til Elliot. Det største gave i livet. Sætter mange tanker i gang. Lettere mangel på nattesøvn efterfølgende.',
                        type: 'life',

                    },
                    {
                        year: '2015-2016',
                        title: 'Junior udvikler',
                        desc: '<p>Junior (selv i den alder) udvikler, Bytelab A/S, Aarhus</p>Bliver første ansat som konsulent til at vedtage kundekontanken, men overgår senere til udviklingsteamet. Foregår primært i C#, MSSQL og Javascript. Stifter dog bekendtskab med andre teknologier også.',
                        type: 'job',

                    },
                    {
                        year: '2015-2016',
                        title: 'Tilbage på skolebænken',
                        desc: '<p>Datalogi, Aarhus Universitet</p>Efter en beslutning om karriereskift vender jeg tilbage på skolebænken på Aarhus Universitet, hvor jeg trækker gennemsnits alderen lidt op for mit hold.',
                        type: 'edu',

                    },
                    {
                        year: '2015',
                        title: 'Line Training Captain',
                        desc: '<p>Jet Time A/S, Billund</p>Flyve som Line Training Captain, d.v.s. at flyve med nye folk udefra, flyve med kaptajnsaspirater og andre træningsopgaver.',
                        type: 'job',

                    },
                    {
                        year: '2013',
                        title: 'I et forhold',
                        desc: 'Bliver kæreste med den dejligste pige i verden. Sammen drager vi ud på livets eventyr både bogstavelig og billedligt talt .',
                        type: 'life',

                    },
                    {
                        year: '2012-2015',
                        title: 'Kaptajn',
                        desc: '<p>Jet Time A/S, Billund</p>Samme selskab, dog nu med mere ansvar og opgaver.',
                        type: 'job',

                    },
                    {
                        year: '2008-2012',
                        title: 'Styrmand',
                        desc: '<p>Jet Time A/S, Billund</p>Primært flyvninger til charter destinationer i Europa og Nord-Afrika. Også en del ad-hoc flyvninger for andre luftfartsselskaber.',
                        type: 'job',

                    },
                    {
                        year: '2007-2008',
                        title: 'Styrmand',
                        desc: '<p>airBaltic, Vilnius, Litauen</p>Første job som pilot. Flyvning på Boeing 737 Classic til lufthavne i Europa. Interessant møde med fremmede kulturer på arbejdspladsen.',
                        type: 'job',

                    },
                    {
                        year: '2006-2007',
                        title: 'Post-omdeler',
                        desc: '<p>Post Danmark. Postbud.</p>Betalt motion. I sne og slud...',
                        type: 'job',

                    },
                    {
                        year: '2005-2006',
                        title: 'Hænderne på vaskebrættet',
                        desc: '<p>Vaskerimedarbejder, Aarhus Universitets Hospital.</p>Heldigvis med maskiner til hjælp.',
                        type: 'job',

                    },
                    {
                        year: '2002-2004',
                        title: 'Luft under vingerne',
                        desc: '<p>Luftfartsskolen, Roskilde. Uddannelse til trafikflyver.</p>Starter med at lære hvordan man letter og lander (hvilket er en god ide at kunne). Efterfølgende bliver det mere og mere advanceret flyvning efter visuelle og derefter efter instrumentflyveregler.',
                        type: 'edu',

                    },
                    {
                        year: '2001-2002',
                        title: 'Datamat konstruktør',
                        desc: '<p>Amitech A/S, Lystrup.</p>Primært samling af dansk produceret PC\'ere til danske marked.',
                        type: 'job',
                    },
                    {
                        year: '2001',
                        title: 'C2',
                        desc: '<p>Kontrol og varslings grundforløb. Flyvestation Skagen. Intercept controller.</p>Anden del af kontrol- og varsling officerslinjen.',
                        type: 'edu',
                    },
                    {
                        year: '2000',
                        title: 'I dronningens klæ\'r... igen.',
                        desc: '<p>Sergentskole på Flyvestation Karup.</p> Første del af officers-grunduddannelsen indenfor kontrol- og varsling grenen.',
                        type: 'edu',
                    },
                    {
                        year: '1999',
                        title: 'I dronningens klæ\'r.',
                        desc: 'Værnepligtig på Flyvestation Karup, eskadrille 661. Lærer hvordan det er at være <i>grøn</i> og lærer at man kan 10 gange så meget som man selv tror, og 100 gange så meget som ens moderen tror man kan.',
                        type: 'job',
                    },
                    {
                        year: '1995-1998',
                        title: 'Student',
                        desc: '<p>Matematisk linje på Aarhus Statsgymnasium.</p>Vælger matematik, kemi og engelsk på højt niveau.',
                        type: 'edu',
                    },
                    {
                        year: '1988-1995',
                        title: 'Hjem til Aarhus',
                        desc: '<p>Flytter hjem til Aarhus igen. Hasle Skole.</p> Det hele bliver igen lidt anderledes end i go\'e gamle Kuwait.',
                        type: 'life',
                    },
                    {
                        year: '1986-1987',
                        title: 'Q8',
                        desc: '<p>Flytter til Kuwait og begynder på Kuwait English School. Al undervisning foregår på engelsk i engelsk skolesystem.</p> Lidt anderledes end go\'e gamle Danmark.',
                        type: 'life',
                    },
                    {
                        year: '1979',
                        title: 'Fødselsdag',
                        desc: 'Født på Silkeborg Sygehus en kold vintermorgen i januar efter den såkaldte <i>vinterkrig</i>.',
                        type: 'life',
                    },
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>


</style>