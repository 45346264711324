let colors = {
    edu: {
        text: 'Uddannelse',
        color: 'teal', 
        mod: 'darken-3', 
    },
    
    life: {
        text: 'Livsbegivenhed',
        color: 'light-blue', 
        mod: 'darken-3',
    },
    job: {
        text: 'Arbejde',
        color: 'deep-purple', 
        mod: 'darken-1',
    },
};

let lifeEventsMixin = {
    
    methods: {
        getLifeEventColor: function(lifeEventType) {
            
            return (lifeEventType === null || colors[lifeEventType] === null) ? null : colors[lifeEventType].color;
            

        },

        getLifeEventModifier: function(lifeEventType) {
            
            return (lifeEventType === null || colors[lifeEventType] === null) ? null : colors[lifeEventType].mod;
            

        },

        getLifeEventTextColor: function(lifeEventType) {
            return this.getLifeEventColor(lifeEventType)+'--text';
        },

        getLifeEventTextFullFormat: function(lifeEventType) {
            return this.getLifeEventTextColor(lifeEventType)+' text--'+this.getLifeEventModifier(lifeEventType);
        },
        getAllColors: function() {
            return colors;
        }
    }
}


export { lifeEventsMixin }